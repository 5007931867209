import {Box, Stack, StackDirection} from '@indoqa/style-system'
import React from 'react'
import {Button, Type} from '../../../commons/components/button/Button'
import akLogo from './logo-ak.svg'
import justizLogo from './logo-justiz.png'
import manzLogo from './logo-manz.svg'
import oegbLogo from './logo-oegb.png'
import oegbvLogo from './logo-oegbv.svg'

export enum Provider {
  OEGB = 'ÖGB',
  OEGBV = 'ÖGB-Verlag',
  AK = 'Arbeiterkammer',
  MANZ = 'Manz',
  JUSTIZ = 'Justiz',
}

interface Props {
  provider: Provider
  onClick: () => void
}

const buttonLabels: Record<Provider, string> = {
  [Provider.OEGB]: 'Login für Mitarbeiter:innen der Gewerkschaften',
  [Provider.OEGBV]: 'Login für Kund:innen des ÖGB Verlags',
  [Provider.AK]: 'Login für AK Mitarbeiter:innen',
  [Provider.MANZ]: 'Login für Manz-Kund:innen',
  [Provider.JUSTIZ]: 'Login für Mitarbeiter:innen des Bundesministeriums für Justiz',
}

const getLogo = (provider: Provider): string => {
  switch (provider) {
    case Provider.OEGB:
      return oegbLogo
    case Provider.AK:
      return akLogo
    case Provider.OEGBV:
      return oegbvLogo
    case Provider.MANZ:
      return manzLogo
    case Provider.JUSTIZ:
      return justizLogo
  }
}

export const LoginProviderPanel = ({provider, onClick}: Props) => {
  const label = buttonLabels[provider]
  return (
    <Stack stackDirection={[StackDirection.VERTICAL]} alignItems="center" spacing={6}>
      <Box onClick={onClick} cursorPointer>
        <img src={getLogo(provider)} alt={label} style={{height: 50}} />
      </Box>
      <Box>
        <Button linkTo={onClick} text={label} type={Type.REDUCED} whiteSpaceWrap />
      </Box>
    </Stack>
  )
}
