import {Box, Text} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Link, useHistory} from 'react-router-dom'
import {textColorLinksRules, undecoratedLinksRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {Badge} from '../../../ar/components/badge/Badge'
import {arPathPlusInfo} from '../../../ar/store/ar.paths'
import {Pool} from '../../idqsa/idqsa'
import {SelectSignal} from '../../idqsa/SelectSignal'
import {Availability} from '../../types/SearchResult'
import {formatDate} from '../../utils/formatDate'

export interface UserResourceAvailability {
  available: boolean
  requiredAvailability?: Availability
  denialInfo?: string
}

type Spacing = 0 | 1 | 2 | 3 | 4

interface Props {
  resultItemId: string
  searchId?: string
  name: string
  source?: string
  sourceDate?: Date
  chapterNames?: string[]
  typeBgColor?: string | keyof Theme['colors']
  type: string
  link?: string
  pb?: Spacing
  disableBadge?: boolean
  userResourceAvailability?: UserResourceAvailability
  pool: Pool
  breadcrumbs?: React.ReactNode
  children: React.ReactNode
}
export const ResultItemTemplate = (props: Props) => {
  const {
    name,
    source,
    sourceDate,
    pb = 6,
    type,
    disableBadge,
    typeBgColor = 'primary',
    link,
    userResourceAvailability,
    breadcrumbs,
    pool,
    children,
  } = props
  const {css, theme} = useFela<Theme>()
  const history = useHistory()

  const notAvailable = userResourceAvailability && !userResourceAvailability.available

  const nameComponent = link ? <Link to={link}>{name}</Link> : name
  const titleComponent = notAvailable ? (
    <h3 className={css({color: theme.colors.textReduced, marginTop: 2, marginBottom: 1}, undecoratedLinksRules())}>
      {!disableBadge && <Badge bg={typeBgColor}>{type}</Badge>}
      {name}
    </h3>
  ) : (
    <h3 className={css({marginTop: 2, marginBottom: 1}, textColorLinksRules(theme), undecoratedLinksRules())}>
      {!disableBadge && <Badge bg={typeBgColor}>{type}</Badge>}
      {nameComponent}
    </h3>
  )
  return (
    <SelectSignal resultItemId={props.resultItemId} searchId={props.searchId} pool={pool}>
      {notAvailable && (
        <a
          style={{color: theme.colors.accent}}
          href={arPathPlusInfo()}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.preventDefault()
            history.push(arPathPlusInfo())
          }}
        >
          {userResourceAvailability?.denialInfo}
        </a>
      )}
      <Box pb={pb} fullWidth color={notAvailable ? theme.colors.textReduced : undefined}>
        {source && (
          <Box fontSize="small" mt={1}>
            {breadcrumbs}
            {sourceDate && (
              <>
                (Rechtsstand: <Text style={{whiteSpace: 'nowrap'}}>{formatDate(sourceDate)})</Text>
              </>
            )}
          </Box>
        )}
        {titleComponent}
        {children}
      </Box>
    </SelectSignal>
  )
}
