import {Box, Col, ColRow, Flex, Grid, Text} from '@indoqa/style-system'
import * as React from 'react'
import {arPathContentResources, arPathSearch} from '../../store/ar.paths'
import {ArLink} from '../links/ArLink'
import {ExclusivePlusLink} from '../links/ExclusivePlusLink'
import {ArSearchInput} from '../search/ArSearchInput'

export const ArOverviewPanel = () => {
  return (
    <Box fullWidth>
      <Flex mb={28} pb={6} mt={16} justifyContent="center" fullWidth direction="column">
        <Box textAlign="center">
          <h1>Arbeitsrecht für Betriebsräte</h1>
        </Box>
        <Box textAlign="center">
          <p>Arbeitsrechtliche Fragen als Betriebsrat einfach und schnell lösen!</p>
        </Box>

        <Flex fullWidth center py={3}>
          <Box maxWidth={750} fullWidth>
            <ArSearchInput />
          </Box>
        </Flex>

        <Box textAlign="center">
          <Text pr={2}>
            <ArLink link={arPathContentResources()} name="Inhalte" />
          </Text>{' '}
          <Text pl={2}>
            <ArLink link={'/login'} name="Login" />
          </Text>
        </Box>

        <Box pt={12}>
          <h2>Ich suche Infos zum Thema</h2>
        </Box>
        <Grid fullWidth>
          <ColRow>
            <Col size={[6]}>
              <ul>
                <li>
                  <ArLink name="Pflegefreistellung" link={arPathSearch('pflegefreistellung')} />
                </li>
                <li>
                  <ArLink
                    name={`Arbeitskräfte${String.fromCharCode(173)}überlassung`}
                    link={arPathSearch('Arbeitskräfteüberlassung')}
                  />
                </li>
                <li>
                  <ArLink name="Lohn- und Sozialdumping" link={arPathSearch('Lohn- und Sozialdumping')} />
                </li>
              </ul>
            </Col>
            <Col size={[6]}>
              <ul>
                <li>
                  <ArLink name="Betriebsratsfonds" link={arPathSearch('Betriebsratsfonds')} />
                </li>
                <li>
                  <ArLink name="Datenschutz" link={arPathSearch('Datenschutz')} />
                </li>
                <li>
                  <ArLink name="Abfertigung" link={arPathSearch('Abfertigung')} />
                </li>
              </ul>
            </Col>
          </ColRow>
        </Grid>

        <Box pt={12}>
          <h2>
            Fragen und Antworten zum Thema Abfertigung <ExclusivePlusLink />
          </h2>
        </Box>
        <Box>
          <ul>
            <li>Wo ist die Abfertigung gesetzlich geregelt?</li>
            <li>Wonach bemisst sich die Höhe der Abfertigung?</li>
            <li>Wann wird die Abfertigung ausbezahlt?</li>
          </ul>
        </Box>

        <Box pt={12}>
          <h2>
            Leitfaden Betriebsvereinbarungen <ExclusivePlusLink />
          </h2>
        </Box>
        <Box>
          Betriebsvereinbarungen sind das zentrale Instrument zur Mitbestimmung im Betrieb. Im „Leitfaden
          Betriebsvereinbarungen“ findest du alle Infos zu den unterschiedlichen Arten von Betriebsvereinbarungen und
          was du beim Abschluss einer Betriebsvereinbarung beachten solltest.
        </Box>

        <Box pt={12}>
          <h2>Arbeitsrecht für Betriebsräte PLUS</h2>
        </Box>
        <Box>
          <ul>
            <li>
              <ArLink
                name="Was ist Arbeitsrecht für Betriebsräte PLUS?"
                link="https://arbeitsrecht-fuer-betriebsraete.at/"
              />
            </li>
            <li>
              <ArLink
                name="Welche Vorteile bietet Arbeitsrecht für Betriebsräte PLUS?"
                link="https://arbeitsrecht-fuer-betriebsraete.at/vorteile/"
              />
            </li>
            <li>
              <ArLink
                name="Welche Inhalte stehen dir in Arbeitsrecht für Betriebsräte PLUS zur Verfügung?"
                link="https://digital.oegbverlag.at/arbeitsrecht-all"
              />
            </li>
            <li>
              <ArLink
                name="Was kostet Arbeitsrecht für Betriebsräte PLUS?"
                link="https://shop.oegbverlag.at/arbeitsrecht-fuer-betriebsraete-plus"
              />
            </li>
          </ul>
        </Box>

        <Box pt={12}>
          <h2>Deine Optionen</h2>
        </Box>
        <Box>
          <ul>
            <li>
              <ArLink
                name="Arbeitsrecht für Betriebsräte PLUS kostenlos testen"
                link="https://arbeitsrecht-fuer-betriebsraete.at/testzugang/"
              />
            </li>
            <li>
              <ArLink
                name="An einer Online-Präsentation von Arbeitsrecht für Betriebsräte PLUS teilnehmen"
                link="https://arbeitsrecht-fuer-betriebsraete.at/praesentation/"
              />
            </li>
            <li>
              <ArLink
                name="Mit dem Team von Arbeitsrecht für Betriebsräte PLUS Kontakt aufnehmen"
                link="https://arbeitsrecht-fuer-betriebsraete.at/kontakt/"
              />
            </li>
            <li>
              <ArLink
                name="Arbeitsrecht für Betriebsräte PLUS bestellen"
                link="https://shop.oegbverlag.at/arbeitsrecht-fuer-betriebsraete-plus"
              />
            </li>
          </ul>
        </Box>
      </Flex>
    </Box>
  )
}
