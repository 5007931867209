import {Box, Flex, Text} from '@indoqa/style-system'
import * as React from 'react'
import {Button, Type} from '../../commons/components/button/Button'
import {ReaderFooterPanel} from '../../commons/components/footer/ReaderFooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {MainContainerPanel} from '../../commons/components/main/MainContainerPanel'
import {ReaderLayout} from '../../commons/layouts/reader-layout/ReaderLayout'
import {ARHeaderPanel} from '../components/header/ARHeaderPanel'
import {ArLink} from '../components/links/ArLink'
import {arPathPlusInfo} from '../store/ar.paths'

export const ArPlusInfoPage = () => {
  const arHeader = <ARHeaderPanel />
  const arFooter = <ReaderFooterPanel />
  const canonical = arPathPlusInfo()

  return (
    <ReaderLayout header={arHeader} footer={arFooter} enableScrollTop>
      <DigitalHelmet title="Arbeitsrecht für Betriebsräte" canonical={canonical} />
      <MainContainerPanel>
        <Flex direction="column" fullWidth>
          <Box fullWidth textAlign="center" pt={12}>
            <h2>Weiterlesen mit dem „Arbeitsrecht für Betriebsräte PLUS“-Jahresabo</h2>
          </Box>
          <Box fullWidth textAlign="center">
            Du hast bereits ein Abo? <ArLink name="Hier anmelden" link="/login" />
          </Box>
          <Box fullWidth textAlign="center" pt={12}>
            <h2>„Arbeitsrecht für Betriebsräte PLUS“-Jahresabo</h2>
          </Box>
          <Box fullWidth textAlign="center">
            Bis zu 5 Zugänge: 330,- Euro inkl. MwSt.
            <br />
            Bis zu 10 Zugänge: 660,- Euro inkl. MwSt.
            <br />
            Bis zu 20 Zugänge: 990,- Euro inkl. MwSt.
            <br />
          </Box>
          <Box fullWidth textAlign="center" pt={4}>
            <Text pr={4}>
              <ArLink
                name="Mehr Infos zu Arbeitsrecht für Betriebsräte PLUS"
                link="https://arbeitsrecht-fuer-betriebsraete.at/"
              />
            </Text>
            <Text pl={4}>
              <ArLink name="Kontakt" link="https://arbeitsrecht-fuer-betriebsraete.at/kontakt/" />
            </Text>
          </Box>
          <Box pt={12}>
            <p>
              Mit Arbeitsrecht für Betriebsräte PLUS hast du Zugriff auf die Inhalte folgender Ratgeber und Fachbücher:
            </p>
            <ul>
              <li>Arbeitsrecht in Frage und Antwort</li>
              <li>Arbeitsverfassungsgesetz in Frage und Antwort</li>
              <li>Arbeitszeitrecht in Frage und Antwort</li>
              <li>Urlaubsrecht in Frage und Antwort</li>
              <li>Betriebsratsarbeit in der Praxis</li>
              <li>Betriebsratsfonds</li>
              <li>Arbeitsrecht/Der Löschnigg</li>
              <li>Leitfaden Betriebsvereinbarung</li>
            </ul>
            <p>
              Weiters kannst du rund 25 Skripten des VÖGB und die Videos aus der Reihe „Arbeitsrecht in 90 Sekunden“
              nutzen.
            </p>
          </Box>
          <Box fullWidth textAlign="center" pt={4} pb={20}>
            <Button
              type={Type.CTA}
              linkTo="https://shop.oegbverlag.at/arbeitsrecht-fuer-betriebsraete-plus/"
              text="JETZT BESTELLEN"
            />
          </Box>
        </Flex>
      </MainContainerPanel>
    </ReaderLayout>
  )
}
